// theme: https://colorhunt.co/palette/d4ecdd345b63152d35112031

$winter-bg: #112031;
$winter-theme: #152D35;
$winter-high: #345B63;
$winter-em: #D4ECDD;

html:not([data-mode="lit"]) {
    --bg: #112031;
    --theme: #152D35; }

.button {
    background-color: $winter-high !important;
    border-color: $winter-high !important;
    color: $winter-em !important;
    &_tally {
        background-color: $winter-theme !important; }
    &_translucent {
        color: $winter-em !important; } }

.nav_header {
    background-color: $winter-theme !important; }

.to_top {
    background-color: $winter-high !important;
    color: $winter-em !important; }

.excerpt_thumbnail {
  max-height: 16rem; }

.excerpt_thumbnail img {
      max-height: 15rem; }
