.center {
    text-align: center; }

.split {
    display: flex;
    p {
        padding: 8px; }
    .text-left {
        padding-right: 16px; } }

/* shortcode: columns */
.columns {
  display: grid;
  grid-template-columns: repeat(var(--column-count), 1fr);
  gap: 1rem; }

.column {
  break-inside: avoid; }

html:not([data-mode="lit"]) {
  .link_owner .icon {
    filter: invert(1); } }

@media only screen and (min-width: 600px) {
    figure.img-500 img,
    .img-500 figure img,
    .img-500 p img {
        height: 500px; }

    figure.img-400 img,
    .img-400 figure img,
    .img-400 p img {
        height: 400px; }

    figure.img-300 img,
    .img-300 figure img,
    .img-300 p img {
        height: 300px; }

    figure.img-250 img,
    .img-250 figure img,
    .img-250 p img {
        height: 250px; }

    figure.img-200 img,
    .img-200 figure img,
    .img-200 p img {
        height: 200px; }

    figure.img-150 img,
    .img-150 figure img,
    .img-150 p img {
        height: 150px; }

    figure.img-100 img,
    .img-100 figure img,
    .img-100 p img {
        height: 100px; }
    .img-min-width-300 p img {
      min-width: 300px; } }

@media only screen and (max-width: 600px) {
    .split {
        display: block; } }


